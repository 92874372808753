.mega-menu__container {
    max-width: 100%;
    height: 0;

    padding-right: 130px;
    padding-left: 130px;

    overflow: hidden;
}

@include media-breakpoint-up(xl) {
    .mega-menu__container {
        position: fixed;
        top: 60px;
        z-index: 50;

        width: 100vw;
        height: 0;
        padding: 0;

        background-color: transparent;

        transition: height $animation-speed * 3, background-color $animation-speed * 3;

        &.js-nested-menu--active {
            height: calc(100vh);

            background-color: rgba(0, 0, 0, 0.57);

            transition: height $animation-speed * 3;
        }
    }

    .mega-menu {
        position: absolute;

        width: 100%;
        height: 0;

        border-radius: 0 0 2px 2px;

        overflow: hidden;

        background-color: $color-septenary-100;

        transition: height $animation-speed * 2;

        .submenu__navigation-link.js-nested-menu--active {
            display: flex;

            opacity: 1;
        }

        .submenu:first-of-type {
            opacity: 0;

            transition: opacity $animation-speed * 2;
        }

        &.js-nested-menu--active {
            height: 610px;

            .submenu:first-of-type {
                opacity: 1;

                transition-delay: $animation-speed * 2;
            }
        }

        .link-icon__icon {
            margin-left: auto;
        }
    }

    .mega-menu__content-container {
        position: relative;

        max-width: calc(#{map-get($container-max-widths, xl)} - 60px);
        padding: 0;
    }

    .mega-menu__background-images {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 330px;

        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    .mega-menu__background-image {
        position: absolute;
        top: 0;
        left: 0;

        z-index: 1;

        height: 610px;

        opacity: 0;

        transition: opacity $animation-speed * 2;

        &.js-nested-menu--active {
            z-index: 2;

            opacity: 1;
        }
    }

    .mega-menu__submenu-container {
        position: relative;
        z-index: 5;

        display: flex;
        height: 610px;

        .submenu {
            position: relative;
            top: 0;
            bottom: 0;

            width: 0;
            height: 610px;
            padding: 20px 0;

            overflow: hidden;

            transition: width $animation-speed * 2, padding $animation-speed * 2;

            .simplebar-track.simplebar-vertical {
                top: 30px;
                bottom: 30px;
            }

            &--active,
            &.js-nested-menu--active {
                width: auto;
                min-width: 330px;
                max-width: 375px;
                padding: 20px;
            }
        }

        .submenu__navigation {
            width: 100%;
            min-width: 200px;
        }
    }

    .mega-menu__teaser-container {
        position: relative;
        top: 0;
        bottom: 0;

        width: 100%;
        max-width: 2 / $mega-menu-columns * 100%;
    }

    .mega-menu__teaser {
        position: absolute;
        right: 60px;
        bottom: 60px;
        left: 60px;
        z-index: 2;

        opacity: 0;

        transition: opacity $animation-speed * 2;

        &.js-nested-menu--active {
            z-index: 3;

            opacity: 1;
        }

        &--top {
            top: 30px;
        }
    }

    .js-nested-menu--active {
        display: block;
    }
}
